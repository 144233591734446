import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { RegistroService } from 'src/app/services/registro.service';
import { RolJuegosService } from 'src/app/services/roljuegos.service';
import { CompilacionService } from 'src/app/services/compilacion.service';


@Component({
  selector: 'app-compilacion',
  templateUrl: './compilacion.component.html',
  styleUrls: ['./compilacion.component.css']
})
export class CompilacionComponent implements OnInit {
  myControl= new FormControl();
  Equipos: any;
  EquiposBuscados: any;
  PremiosData : any;
  Partidos : any;
  Jornadas : any;
  Temporadas : any;
  Categorias : any;
  Fechas : any;
  TablaPosicion : any;
  compilacionBateo : any;
  compilacionPitcheo : any;
  temporada = "";
  jornada = "";
  categoriaID = 4;
  categoria = "";
  temporadaID = 22;
  roljuegoID = 0;
  jornadaID = 269;
  jornada_vista = "";

  constructor(
    private roljuegos_service : RolJuegosService,
    private registro_service : RegistroService,
    private compilacion_service : CompilacionService
  ) {}

  ngOnInit(): void {
    this.JornadaCompilacion();
    this.recuperarPremios();
    
    
  }

  recuperarPremios(){
    this.PremiosData = [
      { titulo_amarillo : "MEJOR", titulo : "ENTRENADOR", fecha : "NOVIEMBRE 2021", foto_copa : "https://apiliga.serteza.com/storage/anuncios/anuncio-1.jpg"},
      { titulo_amarillo : "MEJOR", titulo : "JUGADOR", fecha : "NOVIEMBRE 2021", foto_copa : "https://apiliga.serteza.com/storage/anuncios/anuncio-2.jpg"},
      { titulo_amarillo : "CAMPEON", titulo : "", fecha : "NOVIEMBRE 2021", foto_copa : "https://apiliga.serteza.com/storage/anuncios/anuncio-3.jpg"}
    ]
  }

JornadaCompilacion(){

  this.Equipos = [];
  this.compilacion_service.obtenerJornadaCompilacion()
  .subscribe((object : any) => {
    if(object.ok){
      this.temporadaID = object.data[0].TemporadaID;
      this.temporada = object.data[0].Temporada
      this.jornada = object.data[0].Jornada;
      this.EquiposTemporada();
    }
  });
}

  EquiposTemporada(){
    let json = {
      TemporadaID : this.temporadaID,
    };
    
    this.Equipos = [];
    this.roljuegos_service.equiposTemporada(json)
    .subscribe((object : any) => {
      if(object.ok){
        this.Equipos = object.data;
        this.EquiposBuscados = object.data;
      }
    });
  }


  EquipoSeleccionado(InscripcionID:number){
    
    this.buscarBateo(InscripcionID);
    this.buscarPitcheo(InscripcionID);
   
  }
  buscarBateo(busInscripcionID:number){
    
    let json = {
      InscripcionID : busInscripcionID
    };
    
    this.compilacionBateo = [];
    this.compilacion_service.obtenerBateo(json)
    .subscribe((object : any) => {
      if(object.ok){
        this.compilacionBateo = object.data;
        
      }
    });
  }

  buscarPitcheo(busInscripcionID:number){
    
    let json = {
      InscripcionID : busInscripcionID
    };
    
    this.compilacionBateo = [];
    this.compilacion_service.obtenerPitcheo(json)
    .subscribe((object : any) => {
      if(object.ok){
        this.compilacionPitcheo = object.data;
        
      }
    });
  }

  buscarEquipo(){
    
    this.Equipos = [];
    this.EquiposBuscados.forEach((element : any) => {
      this.Equipos.push({
        "Equipo" : element.Equipo,
        "InscripcionID" : element.InscripcionID
      });
    });
    if(this.myControl.value.length > 0){
      this.Equipos = [];
      this.EquiposBuscados.forEach((element : any) => {
        if(element.Equipo.includes(this.myControl.value.toUpperCase())){ 
          this.Equipos.push({
            "Equipo" : element.Equipo,
            "InscripcionID" : element.InscripcionID
          });
        }
      });
    }
  }
}
