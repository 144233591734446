<nav class="navbar navbar-expand-lg navbar-white sticky-top" id="navigation-example">
    <div class="container-fluid">
        <div class="navbar-wrapper">
        <a class="navbar-brand" id="titlulo_header">Mi equipo</a>
        </div>
        <button class="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation" data-target="#navigation-example">
        <span class="sr-only">Toggle navigation</span>
        <span class="navbar-toggler-icon icon-bar"></span>
        <span class="navbar-toggler-icon icon-bar"></span>
        <span class="navbar-toggler-icon icon-bar"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end">
        
        <ul class="navbar-nav">
            <li class="nav-item">
            <a class="nav-link" href="javascript:void(0)">
                <i class="material-icons">dashboard</i>
                <p class="d-lg-none d-md-block">
                Stats
                </p>
            </a>
            </li>
            <li class="nav-item dropdown" *ngIf="show">
            <a class="nav-link" href="javscript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-cog" style="font-size: 20px;"></i>
                <p class="d-lg-none d-md-block">
                    Configuraciones avanzadas
                </p>
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                <a class="dropdown-item" href="javascript:void(0)" (click)="openModal()">Recuperar contraseña</a>
                <a class="dropdown-item" href="javascript:void(0)">En proceso ...</a>
            </div>
            </li>
            <li class="nav-item">
            <a class="nav-link" href="javascript:void(0)">
                <i class="material-icons">person</i>
                <p class="d-lg-none d-md-block">
                Account
                </p>
            </a>
            </li>
        </ul>
        </div>
    </div>
</nav>
<ng-template #content let-modal>
    <div class="modal-header">
        <h2>Recuperación de contraseñas</h2>
    </div>
    <div class="modal-body">
        <div class="form">
            <div class="form-row">
                <div class="col-12">
                    <mat-form-field class="w-100">
                        <mat-label>Buscar credenciales</mat-label>
                        <input type="text"
                               placeholder="Utilice el Nombre del equipo, El Correo o Nombre del representante"
                               aria-label=""
                               matInput
                               [(ngModel)]="busqueda"
                               [formControl]="myControl"
                               [matAutocomplete]="auto"
                               (keyup)="mostrarUsuarios()"
                               autofocus="false">
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)='selectEquipo($event)'>
                          <mat-option *ngFor="let usuario of usuarios" [value]="usuario.Equipo" [id]="usuario.UsuarioID">
                            {{usuario.Equipo}} - {{usuario.nombre}}
                          </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
            <div class="form-row" [hidden]="mostrar">
                <div class="card border border-success rounded">
                    <div class="card-body">
                        <div class="col-12">
                            <h3>Estas son las llaves de acceso</h3>
                        </div>
                        <div class="col-12">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend my-0 py-0">
                                  <span class="input-group-text my-0 py-0" id="basic-addon1"><i class="fas fa-user"></i></span>
                                </div>
                                <input type="text" [(ngModel)]="info.usuario" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" disabled>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend my-0 py-0">
                                  <span class="input-group-text my-0 py-0" id="basic-addon1"><i class="fas fa-key"></i></span>
                                </div>
                                <input type="text" [(ngModel)]="info.pass" class="form-control" placeholder="Password" aria-label="Username" aria-describedby="basic-addon1" disabled>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-danger" (click)="cerrarModal()"><i class="far fa-times-circle"></i> Cancelar</button>
    </div>
</ng-template>