<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header card-header-primary">
                      <h4 class="card-title">REPORTES</h4>
                      <p class="card-category">Lista de reportes disponibles</p>
                    </div>
                    <div class="card-body">
                      <div class="table-responsive">
                        <table class="table table-hover">
                            <tbody>
                                <tr style="vertical-align: middle;">
                                    <td>
                                        REPORTE DE EQUIPOS INSCRITOS A LA TEMPORADA
                                    </td>
                                    <td>
                                        <button class="btn btn-warning" (click)="visualizar(1)"><i class="fas fa-eye"></i></button>
                                        <button class="btn btn-success" (click)="descargar(1)"><i class="fas fa-file-download"></i></button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>REPORTE DE EQUIPOS NO INSCRITOS A LA TEMPORADA</td>
                                    <td>
                                        <button class="btn btn-warning" (click)="visualizar(2)"><i class="fas fa-eye"></i></button>
                                        <button class="btn btn-success" (click)="descargar(2)"><i class="fas fa-file-download"></i></button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>REPORTE DE COMPLETO</td>
                                    <td>
                                        <button class="btn btn-warning" (click)="visualizar(3)"><i class="fas fa-eye"></i></button>
                                        <button class="btn btn-success" (click)="descargar(3)"><i class="fas fa-file-download"></i></button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>REPORTE DE JUGADORES</td>
                                    <td>
                                        <button class="btn btn-success" (click)="descargar(4)"><i class="fas fa-file-download"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                      </div>
                    </div>
                </div>
            </div>
                <!-- <embed
                src="data:application/pdf;base64,"
                type="application/pdf" 
                width="100%" 
                height="600px"/> -->
        </div>
        
    </div>
</div>
