<!DOCTYPE html>
<html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Liga Yucatán</title>
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Abel&family=Kanit:wght@300;400;500;600&display=swap" rel="stylesheet">
    </head>

    <body>
        <app-header></app-header>
        <section class="mainPage py-5 border px-lg-0 px-2" style="background-color: #EDEFF4;">
            <router-outlet></router-outlet>
        </section>
        <app-footer></app-footer>
    </body>
</html>