<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header card-header-primary">
                        <h4 class="card-title">INSCRIPCIONES</h4>
                        <p class="card-category">{{temporada}}</p>
                    </div>
                    <div class="card-body">
                        <mat-tab-group animationDuration="1000ms" mat-align-tabs="center">
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <span (click)="pintarInscripciones(temporadaid)">Inscripciones</span>
                                </ng-template>
                                <div class="row">
                                    <form class="example-form">
                                        <mat-form-field class="w-100" appearance="fill">
                                            <mat-label>Equipos</mat-label>
                                            <input type="text" placeholder="Seleccione un Equipo" [formControl]="myControl" (keyup)="buscarEquipo()" matInput [matAutocomplete]="auto">
                                            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="EquipoSeleccionado($event.option.id)">
                                                <mat-option *ngFor="let equipo of detalle_ins" [id]="equipo.InscripcionID" [value]="equipo.EquipoSeleccion">
                                                    {{equipo.EquipoSeleccion}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </form>
                                </div>

                                <div class="container">
                                    <div class="row">
                                        <div class="table-responsive">
                                            <table class="table table-hover">
                                                <thead>
                                                    <th>Equipo</th>
                                                    <th>Equipo</th>
                                                    <th>Categoría</th>
                                                    <th>Representante</th>
                                                    <th>Acciones</th>
                                                </thead>
                                                <tbody *ngIf="detalle_ins.length > 0; else pintaVacioIns">
                                                    <tr *ngFor="let inscripcion of detalle_ins">
                                                        <td>
                                                            <img [src]="inscripcion.LogoEquipo" alt="" class="roundend" height="50" width="50">
                                                        </td>
                                                        <td>{{inscripcion.Equipo}}</td>
                                                        <td>{{inscripcion.Categoria}}</td>
                                                        <td>{{inscripcion.Representante}}</td>
                                                        <td>
                                                            <!-- <mat-slide-toggle [(ngModel)]="inscripcion.Editable" (change)="bloquear(inscripcion.InscripcionID,$event)">Bloquear</mat-slide-toggle> -->
                                                            <button class="btn-info btn-sm mx-1" (click)="verEquipo(inscripcion.InscripcionID)" title="Visualizar equipo"><i class="fas fa-users"></i></button>
                                                            <button class="btn-warning btn-sm mx-1" (click)="visualizar(inscripcion.InscripcionID)" title="Visualizar recibo de pago"><i class="fas fa-eye text-white"></i></button>                                                            
                                                            <button class="btn-primary btn-sm mx-1" (click)="modificarEquipo(inscripcion.InscripcionID)" title="Clasificación de Equipos"><i class="fas fa-check text-white"></i></button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <ng-template #pintaVacioIns>
                                                    <tbody>
                                                        <tr class="text-center">
                                                            <td colspan="5">Aún no hay equipos inscritos</td>
                                                        </tr>
                                                    </tbody>
                                                </ng-template>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </mat-tab>
                            <mat-tab label="Solicitudes">
                                <div class="container">
                                    <div class="row">
                                        <div class="table-responsive">
                                            <table class="table table-hover">
                                                <thead>
                                                    <th>Equipo</th>
                                                    <th>Id solicitud</th>
                                                    <th>Equipo</th>
                                                    <th>Representante</th>
                                                    <th>Telefono</th>
                                                    <th>Acciones</th>
                                                </thead>
                                                <tbody *ngIf="solicitudes.length > 0; else pintaVacio">
                                                    <tr *ngFor="let solicitud of solicitudes">
                                                        <td>
                                                            <img [src]="solicitud.LogoEquipo" alt="" class="roundend" height="50" width="50">
                                                        </td>
                                                        <td>{{solicitud.InscripcionID}}</td>
                                                        <td>{{solicitud.Equipo}}</td>
                                                        <td>{{solicitud.Representante}}</td>
                                                        <td>{{solicitud.TelRep}}</td>
                                                        <td>
                                                            <button class="btn btn-warning" (click)="visualizar(solicitud.InscripcionID)" title="Visualizar recibo de pago"><i class="fas fa-eye"></i></button>
                                                            <div ngbDropdown class="d-inline-block">
                                                                <button ngbDropdownToggle class="btn btn-info" title="Validar Solicitud"><i class="far fa-check-square"></i></button>
                                                                <div ngbDropdownMenu>
                                                                    <a ngbDropdownItem (click)="validar(solicitud.InscripcionID,1)" style="cursor: pointer;"><i class="fas fa-check"></i> &nbsp; Aceptar</a>
                                                                    <a ngbDropdownItem (click)="validar(solicitud.InscripcionID,2)" style="cursor: pointer;"><i class="fas fa-times"></i> &nbsp; Rechazar</a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <ng-template #pintaVacio>
                                                    <tbody>
                                                        <tr class="text-center">
                                                            <td colspan="5">Aún no se tienen solicitudes en esta temporada</td>
                                                        </tr>
                                                    </tbody>
                                                </ng-template>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </mat-tab>
                            <mat-tab label="Rechazados"> Content 3 </mat-tab>
                        </mat-tab-group>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #content let-modal>
    <div class="modal-body">
        <div class="form">
            <div class="form-row">
                <div class="col-12">
                    <div class="form-group">
                        <label class="bmd-label-floating">Describa la razon del rechazo</label>
                        <textarea class="form-control" rows="5" name="nota" style="text-transform: uppercase;"></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-danger" (click)="modal.close('Save click')"><i class="far fa-times-circle"></i> Cancelar</button>
        <button class="btn btn-success" (click)="rechazar()"><i class="far fa-check-circle"></i> Continuar</button>
    </div>
</ng-template>
<!-- AQUI DESPLIEGA EL MODAL PARA MODIFICAR EL EQUIPO -->
<ng-template #modEquipo let-modal>
    <div class="modal-header">
        <h2>Clasificación de Equipo</h2>
    </div>
    <div class="modal-body">
        <div class="container my-2">
            <div class="row">
                
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="card card-plain">
                        <div class="card-header card-header-primary">
                            <div class="row">
                                <div class="col-12">
                                    <p class="card-category">CLASIFICACION DE EQUIPO</p>
                                    <h4 class="card-title mt-0">{{datos.equipo}}</h4>
                                    <p class="card-category">{{datos.categoria}}</p>
                                    
                                </div>
                                
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">                                
                                <div class="col-12 mt-1">
                                    <label for="formFile" class="form-label">Nombre del equipo</label>
                                    <input class="form-control" type="text" [(ngModel)]="datosEquipo.equipo" style="text-transform: uppercase;" name="equipo">
                                </div>
                                <div class="col-6 mt-2">
                                    <label for="formFile" class="form-label">Categoria</label>
                                    <select class="form-select" aria-label="Default select example" [(ngModel)]="datosEquipo.categoriaID">
                                            <option selected disabled>Selecciona la categoria del equipo</option>
                                            <option *ngFor="let categoria of categorias" value="{{categoria.CategoriaID}}">{{categoria.Categoria}}</option>
                                    </select>
                                </div>
                                <div class="col-3 mt-2">
                                    <label for="formFile" class="form-label">Clasificacion</label>
                                    <select class="form-select" aria-label="Default select example" [(ngModel)]="datosEquipo.clasificacion">
                                            <option selected disabled>Selecciona la clasificación del equipo</option>
                                            <option *ngFor="let clasific of clasificacion" value="{{clasific.Clasificacion}}">{{clasific.Clasificacion}}</option>
                                    </select>
                                </div>
                                <div class="col-3 mt-2">
                                    <label for="formFile" class="form-label">Grupo</label>
                                    <select class="form-select" aria-label="Default select example" [(ngModel)]="datosEquipo.grupo">
                                            <option selected disabled>Selecciona el grupo que va a participar</option>
                                            <option *ngFor="let grupoeq of grupo" value="{{grupoeq.Grupo}}">{{grupoeq.Grupo}}</option>
                                    </select>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-success" (click)="modificarEq()"><i class="far fa-check-circle"></i> Guardar</button>
        <button class="btn btn-danger" (click)="modal.close('Save click')"><i class="far fa-times-circle"></i> Cerrar</button>
    </div>
</ng-template>
<!--  AQUI TERMINA EL MODAL DEL EQUIPO -->
<ng-template #equipo let-modalTeam>
    <div class="modal-header">
        <h2>Visualización del equipo</h2>
    </div>
    <div class="modal-body">
        <div class="container my-2">
            <div class="row">
                <div class="col-md-4">
                    <div class="card card-profile">
                        <div class="card-avatar">
                            <a href="#pablo">
                                <img class="img" [src]="foto_rep" />
                            </a>
                        </div>
                        <div class="card-body">
                            <h2 class="card-category">REPRESENTANTE</h2>
                            <h4 class="card-title">{{datos.representante}}</h4>
                            <p class="card-description">
                                CURP : &nbsp; {{datos.curp_re}}
                                <br> CORREO : &nbsp; {{datos.correo_re}}
                                <br> TELEFONO : &nbsp; {{datos.telefono_re}}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card card-profile">
                        <div class="card-avatar">
                            <a href="#pablo">
                                <img class="img" [src]="foto_en" />
                            </a>
                        </div>
                        <div class="card-body">
                            <h2 class="card-category">ENTRENADOR</h2>
                            <h4 class="card-title">{{datos.entrenador}}</h4>
                            <p class="card-description">
                                CURP : &nbsp; {{datos.curp_en}}
                                <br> CORREO : &nbsp; {{datos.correo_en}}
                                <br> TELEFONO : &nbsp; {{datos.telefono_en}}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card card-profile">
                        <div class="card-avatar">
                            <a href="#pablo">
                                <img class="img" [src]="foto_ay" />
                            </a>
                        </div>
                        <div class="card-body">
                            <h2 class="card-category">AYUDANTE</h2>
                            <h4 class="card-title">{{datos.ayudante}}</h4>
                            <p class="card-description">
                                CURP : &nbsp; {{datos.curp_ay}}
                                <br> CORREO : &nbsp; {{datos.correo_ay}}
                                <br> TELEFONO : &nbsp; {{datos.telefono_ay}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="card card-plain">
                        <div class="card-header card-header-primary">
                            <div class="row">
                                <div class="col-10">
                                    <h4 class="card-title mt-0">{{datos.equipo}}</h4>
                                    <p class="card-category">LISTA DE JUGADORES</p>
                                    <p class="card-category">{{datos.categoria}}</p>
                                </div>
                                <div class="col-2">
                                    <button class="btn-sm btn-light" title="FICHAS DE EQUIPO" (click)="verReporte(3,0)"><i class="fas fa-folder"></i></button>
                                    <button class="btn-sm btn-light" title="REPORTE DE MI EQUIPO" (click)="verReporte(1,0)"><i class="fas fa-chevron-circle-down"></i></button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-hover">
                                    <thead class="">
                                        <th>ID</th>
                                        <th>Nombre</th>
                                        <th>Fecha nacimiento</th>
                                        <th>Telefono padre</th>
                                        <th>Telefono madre</th>
                                        <th>Ficha</th>
                                    </thead>
                                    <tbody *ngIf="datos.jugadores.length > 0; else pintaVacio">
                                        <tr *ngFor="let jugador of datos.jugadores">
                                            <td>{{jugador.JugadorID}}</td>
                                            <td>{{jugador.nombre}}</td>
                                            <td>{{jugador.FechaNacimiento}}</td>
                                            <td>{{jugador.TelPadre}}</td>
                                            <td>{{jugador.TelMadre}}</td>
                                            <td>
                                                <button class="btn-warning btn-sm" title="REPORTE DEL JUGADOR" (click)="verReporte(2,jugador.JugadorID)"><i class="fas fa-eye text-white"></i></button>
                                                <button class="btn-sm btn-danger" title="ELIMINAR JUGADOR" (click)="eliminarJugador(jugador.JugadorID)"><i class="far fa-trash-alt"></i></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <ng-template #pintaVacio>
                                        <tbody>
                                            <tr>
                                                <td colspan="5" class="text-center">Aun no han registrado jugadores</td>
                                            </tr>
                                        </tbody>
                                    </ng-template>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-danger" (click)="modalTeam.close('Save click')"><i class="far fa-times-circle"></i> Cerrar</button>
    </div>
</ng-template>