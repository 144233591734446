<div class="wrapper">
    <app-sidebar></app-sidebar>
    <div class="main-panel">
        <app-header></app-header>
        <router-outlet></router-outlet>
        <footer class="footer">
            <div class="container-fluid">
                <nav class="float-left">
                <ul>
                    <li>
                    <a href="https://www.serteza.com">
                        SERTEZA
                    </a>
                    </li>
                    <li>
                    <a href="https://www.serteza.com">
                        ACERCA DE NOSOTROS
                    </a>
                    </li>
                </ul>
                </nav>
                <div class="copyright float-right" id="date">
                , desarrollado por Serteza &copy; 2021.
                </div>
            </div>
        </footer>
    </div>
</div>