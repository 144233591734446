<div class="container">
    <div class="row justify-content-between">
        <div class="col-md-12 col-lg-12">
            <div class="row border-bottom pb-2 pb-lg-0">
                <div class="col-lg-7 col-sm-12">
                    <p class="borde-left text-one-sub f18">REGLAS DE LA LIGA</p>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <iframe src="http://docs.google.com/gview?url=https://apiliga.serteza.com/storage/reglas/reglas.pdf&embedded=true"
                     style="width:100%; min-height:500px;" frameborder="0" ></iframe>
                </div>
                </div>
        </div>
    </div>
</div>
