<section class="d-none d-lg-block" style="background-color: #2C2D31 !important;">
    <div class="container">
        <div class="row justify-content-center align-items-center">
            <div class="col-4 d-flex">
                <div class="buttons d-flex mr-3">
                    <a (click)="prev()" class="text-center mr-2"><i class="fas fa-angle-left"></i></a>
                    <a (click)="next()" class="text-center"><i class="fas fa-angle-right"></i></a>
                </div>
                <ngb-carousel #carousel [interval]="5000" [showNavigationArrows]="false" [showNavigationIndicators]="false" class="carousel_aunt">
                    <ng-template *ngFor="let partido of Partidos" ngbSlide>
                        <div class="text-slider d-flex">
                            <p class="f12 text-one">{{partido.fecha}}</p>
                            <p class="f12 text-one text-white">\\</p>
                            <p class="f12 text-one text-white">{{partido.match}}</p>
                        </div>
                    </ng-template>
                </ngb-carousel>
            </div>
            <div class="col-3 paralelograma" style="background-color: #35363C;">
            </div>
            <div class="col-4">
                <div class="row justify-content-end">
                    <div (click)="openModal(2)" class="col-4 d-flex align-items-center log-reg" style="cursor: pointer;">
                        <i class="fas fa-pencil-alt" style="color: #FCEF57;"></i>
                        <p class="text-one text-white my-0 f12 ml-2 nv-n">REGISTRO</p>
                    </div>
                    <div (click)="openModal(1)" class="col-3 d-flex align-items-center border-left log-reg" style="cursor: pointer;">
                        <i class="fas fa-sign-in-alt" style="color: #FCEF57;"></i>
                        <p class="text-one text-white my-0 ml-2 f12 nv-n">INGRESAR</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <nav class="navbar-dark bg-dark navbar-expand-lg" style="background-color: #35363C !important;">
        <div class="collapse container navbar-collapse" id="navbarNav">
            <ul class="navbar-nav pull-sm-left">
                <li class="nav-item mr-3">
                    <!-- <a class="nav-link pl-2 pr-1 mx-1 py-2 my-n2" style="cursor: pointer;"><i class="fab fa-angrycreative" style="font-size: 60px;"></i></a> -->
                </li>
                <li class="nav-item">
                    <!-- <a class="nav-link pl-2 pr-1 mx-1 py-2 my-n2" style="cursor: pointer;"><i class="fab fa-apper" style="font-size: 60px;"></i></a> -->
                </li>
            </ul>
            <ul class="nav navbar-nav navbar-logo mx-auto" style="z-index: 300;">
                <li class="nav-item" style="margin-top: -40px;">
                    <a href="#" class="nav-link pl-2 pr-1 mx-1 py-2 my-n2">
                        <img src="./assets/logos/logo.png" alt="" style="width: 120px;">
                    </a>
                </li>
            </ul>
            <ul class="nav navbar-nav pull-sm-right">
                <li class="nav-item mr-3">
                    <!-- <a class="nav-link pl-2 pr-1 mx-1 py-2 my-n2" style="cursor: pointer;"><i class="fab fa-asymmetrik" style="font-size: 60px;"></i></a> -->
                </li>
                <li class="nav-item">
                    <!-- <a class="nav-link pl-2 pr-1 mx-1 py-2 my-n2" style="cursor: pointer;"><i class="fab fa-aws" style="font-size: 60px;"></i></a> -->
                </li>
            </ul>
        </div>
    </nav>
</section>
<nav class="navbar-dark bg-dark navbar-expand-lg py-3" style="background-color: #2C2D31 !important;">
    <div class="container-fluid d-flex justify-content-end pt-3 pr-2">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
    </div>
    <div class="collapse navbar-collapse menu_space menu" id="navbarSupportedContent">
        <ul class="navbar-nav nv-n mr-auto">
            <li class="nav-item nv-i">
                <a class="nav-link text-one f14" href="#">INICIO <span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item nv-i">
                <a class="nav-link text-one f14" href="#">PARTIDOS</a>
            </li>
            <li class="nav-item dropdown nv-i">
                <a class="nav-link dropdown-toggle text-one f14" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false">
            ACCESOS
          </a>
                <ul>
                    <li><a href="#" style="cursor: pointer;">Rol de Juegos</a></li>
                    <!-- <li><a  style="cursor: pointer;">...</a></li> -->
                    <li><a [routerLink]="['/landing/compilacion']" style="cursor: pointer;">Compilación</a></li>
                    <li><a [routerLink]="['/landing/reglas']" style="cursor: pointer;">Reglamento de la Liga</a></li>
                    <li><a (click)="openModal(1)" style="cursor: pointer;">Ingresar al Portal</a></li>
                    <li><a (click)="openModal(2)" style="cursor: pointer;">Registrar Equipo</a></li>
                </ul>
                <!-- <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a class="dropdown-item" href="#">Rol de Juegos</a>
                    <a class="dropdown-item" [routerLink]="['/landing/compilacion']" style="cursor: pointer;">Compilación</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" [routerLink]="['/landing/reglas']" style="cursor: pointer;">Reglamento de la Liga</a>
                    
                </div> -->
            </li>
            <li class="nav-item nv-i">
                <a class="nav-link text-one f14">ESTADISTICAS</a>
            </li>
        </ul>
        <ul class="navbar-nav ml-md-auto menu_icons">
            <li class="nav-item active_aunt">
                <a class=" pl-2 pr-1 mx-1 py-3 my-n2 link-light" href="https://www.facebook.com/LigaYucatanOficial"><i class="fab fa-facebook" style="font-size: 18px;"></i></a>
            </li>
            <li class="nav-item active_aunt">
                <a class=" pl-2 pr-1 mx-1 py-3 my-n2"><i class="fab fa-instagram" style="font-size: 18px;"></i></a>
            </li>
            <li class="nav-item active_aunt">
                <a class=" pl-2 pr-1 mx-1 py-3 my-n2"><i class="fab fa-google-plus-g" style="font-size: 18px;"></i></a>
            </li>
            <li class="nav-item active_aunt">
                <a class=" pl-2 pr-1 mx-1 py-3 my-n2 link-light" href="https://api.whatsapp.com/message/OHPB4SNVXVK3B1?autoload=1&app_absent=0"><i class="fab fa-whatsapp" style="font-size: 18px;"></i></a>
            </li>
        </ul>
    </div>
</nav>
<div class="carousel_aunt">
    <div class="malla">
        <div class="info">
            <h1>LIGA INFANTIL Y JUVENIL DE BEISBOL YUCATÁN A.C.</h1>
            <p>{{temporadaa}}</p>
            <div (click)="openModal(2)" class="log-reg" style="cursor: pointer; color: #e4f00b;">
                <i class="fas fa-pencil-alt" style="color: #046c2a;"></i>
                <p class="centrado" class="text-one text-red my-0 f16 ml-2 nv-n">{{siguiente_temporada}}</p>
            </div>
        </div>
    </div>
    <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner">
            <div *ngFor="let image of Imagedata; let itemFirst = first;"  class="carousel-item" [ngClass]="itemFirst ? 'active' : ''">
                <img [src]="image" class="d-block w-100 px-0" style="height: 43vh;object-fit: cover;object-position: center center;" alt="First slide">
            </div>
        </div>
    </div>
</div>

<ng-template #contentLogin let-modal>
    <div class="container-fluid mx-0 py-0" style="background-color: #EDEFF4;">
        <div class="row">
            <div class="col-12 header_mod">
                <a (click)="modal.close()" style="cursor: pointer;" class="active_aunt"><i class="fas fa-times"></i></a>
            </div>
        </div>
        <div class="row justify-content-center pb-4 mt-n5">
            <div class="col-10 body_mod">
                <div class="row justify-content-center mt-n3">
                    <div class="col-3">
                        <img src="./assets/logos/logo.png" class="img-fluid">
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <form action="" method="" class="row g-3">
                            <strong class="text-center">LIGA INFANTIL Y JUVENIL DE BEISBOL YUCATAN A.C.</strong>
                            <strong class="text-center">{{temporadaa}}</strong>
                            <div class="col-12">
                                <label>Correo electrónico</label>
                                <input type="text" name="username" class="form-control" placeholder="Username" [(ngModel)]="login.usuario">
                            </div>
                            <div class="col-12">
                                <label>Contraseña</label>
                                <input type="password" name="password" class="form-control" placeholder="Password" [(ngModel)]="login.contra">
                            </div>
                            <div class="col-12">
                                <p class="mb-0" (click)="openModal()">Recupera tu contraseña <a href="#">aquí</a></p>
                            </div>
                            <div class="col-12 text-center">
                                <button class="btn text-white btn-sm" style="background-color: #35AD79;" (click)="Login()">Iniciar sesión</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="row pb-3">
            <div class="col-12">
                <p class="text-center mb-0"> Aún no has registrado a tu equipo? <a class="text-info" style="cursor: pointer;">Registralo</a></p>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #modalReg let-modalReg>
    <div class="container-fluid mx-0 py-0" style="background-color: #EDEFF4;">
        <div class="row">
            <div class="col-12 header_mod">
                <a (click)="modalReg.close()" style="cursor: pointer;" class="active_aunt"><i class="fas fa-times"></i></a>
            </div>
        </div>
        <div class="row justify-content-center pb-4 mt-n5">
            <div class="col-11 body_mod">
                <div class="row justify-content-center mt-n3">
                    <div class="col-3">
                        <img src="./assets/logos/logo.png" class="img-fluid">
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form">
                            <div class="form-row">
                                <div class="col-12 text-center">
                                    <strong class="text-center">REGISTRA A TU EQUIPO</strong>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-12">
                                    <mat-tab-group mat-align-tabs="start" >
                                        <mat-tab label="EQUIPO">
                                            <div class="container mt-3">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="row align-items-center">
                                                            <div class="col-10">
                                                                <label for="formFile" class="form-label">Recibo de pago</label>
                                                                <input class="form-control" type="file" accept=".jpg" (change)="cambiarImagen($event)" name="file">
                                                            </div>
                                                            <div class="col-1 pt-lg-4" style="font-size: 32px;color: #38b44a;">
                                                                <i class="fas fa-camera" (click)="tomarFoto()" style="cursor: pointer;" title="Tomar foto"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 mt-1">
                                                        <label for="formFile" class="form-label">Nombre del equipo</label>
                                                        <input class="form-control" type="text" [(ngModel)]="json.equipo" style="text-transform: uppercase;" name="equipo">
                                                    </div>
                                                    <div class="col-12 mt-2">
                                                        <label for="formFile" class="form-label">Categoria</label>
                                                        <select class="form-select" aria-label="Default select example" [(ngModel)]="json.CategoriaID">
                                        <option selected disabled>Selecciona la categoria del equipo</option>
                                        <option *ngFor="let categoria of categorias" value="{{categoria.CategoriaID}}">{{categoria.Categoria}}</option>
                                      </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </mat-tab>
                                        <mat-tab  label="REPRESENTANTE">
                                            <div class="container mt-2">
                                                <div class="row">
                                                    <div class="col-md-12 col-lg-6 form-group">
                                                        <label for="formFile" class="form-label">CURP</label>
                                                        <input class="form-control" type="text" [(ngModel)]="json.curp" style="text-transform: uppercase;" maxlength="18" required name="curp">
                                                    </div>
                                                    <div class="col-md-12 col-lg-6 form-group">
                                                        <label for="formFile" class="form-label">Telefono</label>
                                                        <input class="form-control" type="text" [(ngModel)]="json.telefono" style="text-transform: uppercase;" maxlength="10" name="telefono">
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-4 col-sm-6 form-group">
                                                        <label for="formFile" class="form-label">Apellido paterno</label>
                                                        <input class="form-control" type="text" [(ngModel)]="json.apellido_p" style="text-transform: uppercase;" required name="apeP">
                                                    </div>
                                                    <div class="col-lg-4 col-sm-6 form-group">
                                                        <label for="formFile" class="form-label">Apellido materno</label>
                                                        <input class="form-control" type="text" [(ngModel)]="json.apellido_m" style="text-transform: uppercase;" required>
                                                    </div>
                                                    <div class="col-lg-4 col-sm-12 form-group">
                                                        <label for="formFile" class="form-label">Nombre (s)</label>
                                                        <input class="form-control" type="text" [(ngModel)]="json.nombres" style="text-transform: uppercase;" required name="apeM">
                                                    </div>
                                                    <div class="col-4 form-group">
                                                        <label for="formFile" class="form-label">Correo</label>
                                                        <input class="form-control" type="text" [(ngModel)]="json.correo" name="correo" required>
                                                    </div>
                                                    <div class="col-4 form-group">
                                                        <label for="formFile" class="form-label">Contraseña</label>
                                                        <input class="form-control" type="password" [(ngModel)]="json.contra" maxlength="50" name="contra" required>
                                                    </div>
                                                    <div class="col-4 form-group">
                                                        <label for="formFile" class="form-label">Repetir contraseña</label>
                                                        <input class="form-control" type="password" name="repit">
                                                    </div>
                                                </div>
                                            </div>
                                        </mat-tab>
                                    </mat-tab-group>
                                </div>
                                <div class="col-12 text-right mt-2">
                                    <button class="btn text-white btn-sm" style="background-color: #35AD79;" (click)="enviar()">Enviar inscripción</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row pb-3">
            <div class="col-12">
                <strong class="text-center mb-0">Aviso : Tu solicitud de inscripción será revisada, te llegará un correo de confirmación para que puedas acceder a la plataforma</strong>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #modal_camera let-camera>
    <div class="modal-header">
        <h2 class="modal-title" id="tituloModal">Tomar fotografia</h2>
    </div>
    <div class="modal-body">
        <div class="container">
            <div class="row">
                <div *ngIf="isCameraExist; else noCameraExist" class="col-12">
                    <div class="border border-success rounded text-center" style="width: 100%;height: 265px;">
                        <webcam [height]="450" [width]="350" [trigger]="triggerObservable" (imageCapture)="handleImage($event)" *ngIf="showWebcam" [switchCamera]="nextWebcamObservable"></webcam>
                    </div>

                    <div *ngIf="errors.length > 0">
                        <h4>Error Messages:</h4>
                        <ul *ngFor="let error of errors">
                            <li>{{ error | json }}</li>
                        </ul>
                    </div>
                </div>

                <ng-template #noCameraExist>
                    <div class="border border-danger rounded text-center" style="display: flex;justify-content: center;align-items: center;width: 450px;height: 350px;">
                        Este dispositivo no cuenta con una camara disponible
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-warning" (click)="camera.close()">Cerrar</button>
        <button *ngIf="isCameraExist" type="button" class="btn btn-success" (click)="takeSnapshot()">Tomar foto</button>
    </div>
</ng-template>
<ng-template #recuperar let-modalRecupera>
    <div class="modal-header">
        <h2>Recuperación de la contraseña</h2>
    </div>
    <div class="modal-body">
        <div class="container my-2">
            <div class="row">
                <div class="col">
                    <div class="input-group mb-3">
                        <input type="email" [(ngModel)]="correo" class="form-control" placeholder="Ingresa el correo con el que te has registrado" aria-label="Recipient's username" aria-describedby="basic-addon2" required>
                        <div class="input-group-append my-0 py-0">
                            <button class="btn btn-success my-0 py-0" type="button" (click)="recuperarContra()"><i class="fas fa-search"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" [hidden]="mostrarInfo">
                <div class="col-sm-12">
                    <div class="card rounded border border-success w-100" style="width: 18rem;" *ngIf="tipo == 1">
                        <div class="card-body">
                            <h5 class="card-title">Hemos encontrado su cuenta</h5>
                            <h6 class="card-subtitle mb-2 text-muted">Valida la información</h6>
                            <p class="card-text">
                                EQUIPO : {{info.equipo}}
                                <br> REPRESENTANTE : {{info.nombre}}
                                <br> TELEFONO : {{info.telefono}}
                                <br> CORREO : {{info.correo}}
                            </p>
                            <a href="#" class="card-link btn-sm float-right" title="Enviar contraseña a correo" style="font-size: 24px;" (click)="enviarContra(0)"><i class="fas fa-envelope-open-text"></i></a>
                        </div>
                    </div>
                    <div class="card rounded border border-danger w-100" style="width: 18rem;" *ngIf="tipo == 2">
                        <div class="card-body">
                            <h5 class="card-title">Lo sentimos, no hemos encontrado su cuenta</h5>
                            <h6 class="card-subtitle mb-2 text-muted">Sin embargo hemos encontrado coincidencias con el usuario proporcionado</h6>
                            <div class="card-text">
                                <div *ngFor="let con of coincidencias" class="row align-items-center border-bottom">
                                    <div class="col-10">
                                        EQUIPO : {{con.Equipo}}
                                        <br> REPRESENTANTE : {{con.nombre}}
                                        <br> TELEFONO : {{con.TelRep}}
                                        <br> CORREO : {{con.MailRep}}
                                    </div>
                                    <div class="col-2">
                                        <a href="#" class="card-link btn-sm float-right" title="Enviar contraseña a correo" style="font-size: 24px;" (click)="enviarContra(con.UsuarioID)"><i class="fas fa-envelope-open-text"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12">
                    <small class="text-warning">**IMPORTANTE**  Si el correo no te llega comunicate con el administrador del sistema</small>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-danger" (click)="closeModal(3)"><i class="far fa-times-circle"></i> Cerrar</button>
    </div>
</ng-template>