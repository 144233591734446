<div class="container">
    <div class="row justify-content-between">
        <div class="col-md-12 col-lg-8">
            <div class="row border-bottom pb-2 pb-lg-0">

                <div class="col-lg-12 col-sm-12">
                    <p class="borde-left text-one-sub f18">{{temporada}} COMPILACION A LA JORNADA {{jornada}}</p>
                </div>
                
                
                
            </div>
            
            <div class="row">
                <form class="example-form">
                    <mat-form-field class="w-100" appearance="fill">
                        <mat-label>Equipos</mat-label>
                        <input type="text" placeholder="Seleccione un Equipo" [formControl]="myControl" (keyup)="buscarEquipo()" matInput [matAutocomplete]="auto">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="EquipoSeleccionado($event.option.id)">
                            <mat-option *ngFor="let equipo of Equipos" [id]="equipo.InscripcionID" [value]="equipo.Equipo">
                                {{equipo.Equipo}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </form>


                <!--
                    <mat-label>Equipos</mat-label>
                        <input type="text" placeholder="Seleccione un Equipo" [formControl]="myControl" (keyup)="buscarEquipo()" matInput [matAutocomplete]="auto">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="EquipoSeleccionado($event.option.id)">
                            <mat-option *ngFor="let equipo of Equipos" [id]="equipo.InscripcionID" [value]="equipo.Equipo">
                                {{equipo.Equipo}}
                            </mat-option>
                        </mat-autocomplete>
                -->
            </div>

            
        </div>

        <div class="row mt-4" >
            <div class="col-12 table-responsive px-0">
                <table  class="table aunt_table border mt-2" >

                    <tr  style="cursor: pointer;">  
                        <thead style="background-color: #35AD79;color: white;">
                            <tr class="f14 text-one-sub">
                                <th>JUGADORES</th> 
                                <th>JJ</th>
                                <th>A</th>
                                <th>VB</th>
                                <th>H</th>
                                <th>PCT</th>
                                <th>C</th>
                                <th>H2</th>
                                <th>H3</th>
                                <th>HR</th>
                                <th>CP</th>
                                <th>B</th>
                                <th>R</th>
                                <th>K</th>
                                <th>DB</th>
                                <th>SH</th>
                            </tr>
                        </thead>
                        <tbody style="background-color: white;">
                            <tr *ngFor="let bateo of compilacionBateo">
                                <td class="text-one-sub f14"   style="vertical-align: middle;">

                                    {{bateo.Jugador}}
                                </td>
                                <td  style="vertical-align: middle; text-align: right;" >
                                    
                                    {{bateo.VJ}}
                                    
                                </td>
                                <td  class="text-one-sub f14" style="vertical-align: middle; text-align: right;" >
                                    {{bateo.V}}
                                </td>
                                <td class="text-one-sub f14" style="vertical-align: middle; text-align: right;">
                                    {{bateo.VB}}
                                </td>
                                <td class="text-one-sub f14" style="vertical-align: middle; text-align: right;">{{bateo.TH}}</td>
                                <td  class="text-one-sub f14" style="vertical-align: middle; text-align: right;">{{bateo.PCT}}</td>
                                <td class="text-one-sub f14" style="vertical-align: middle; text-align: right;">{{bateo.C}}</td>
                                <td class="text-one-sub f14" style="vertical-align: middle; text-align: right;">{{bateo.H2}}</td>
                                <td class="text-one-sub f14" style="vertical-align: middle; text-align: right;">{{bateo.H3}}</td>
                                <td class="text-one-sub f14" style="vertical-align: middle; text-align: right;">{{bateo.HR}}</td>
                                <td class="text-one-sub f14" style="vertical-align: middle; text-align: right;">{{bateo.CE}}</td>
                                <td class="text-one-sub f14" style="vertical-align: middle; text-align: right;">{{bateo.B}}</td>
                                <td class="text-one-sub f14" style="vertical-align: middle; text-align: right;">{{bateo.R}}</td>
                                <td class="text-one-sub f14" style="vertical-align: middle; text-align: right;">{{bateo.K}}</td>
                                <td class="text-one-sub f14" style="vertical-align: middle; text-align: right;">{{bateo.DB}}</td>
                                <td class="text-one-sub f14" style="vertical-align: middle; text-align: right;">{{bateo.SH}}</td>
                            </tr>
                        </tbody>
                    </tr>

                </table>
            </div>
        </div>
        <!-- Aqui inicia el Pitcheo-->
        <div class="row mt-4" >
            <div class="col-12 table-responsive px-0">
                <table  class="table aunt_table border mt-2" >

                    <tr  style="cursor: pointer;">  
                        <thead style="background-color: yellow ;color: #35AD79;">
                            <tr class="f14 text-one-sub">
                                <th>JUGADORES</th> 
                                <th>J.LANZADOS</th>
                                <th>GANADOS</th>
                                <th>PERDIDOS</th>
                                <th>PCT</th>
                                <th>IP</th>
                                <th>JE</th>
                                <th>HA</th>
                                <th>TC</th>
                                <th>CL</th>
                                <th>PCL</th>
                                <th>BB</th>
                                <th>K</th>
                                <th>DB</th>
                            </tr>
                        </thead>
                        <tbody style="background-color: white;">
                            <tr *ngFor="let pitcheo of compilacionPitcheo">
                                <td class="text-one-sub f14"   style="vertical-align: middle;">

                                    {{pitcheo.Jugador}}
                                </td>

                                <td style="vertical-align: middle; text-align: right;" >
                                    {{pitcheo.Jugo}}
                                </td>
                                <td  style="vertical-align: middle; text-align: right;" >
                                    {{pitcheo.Ganados}}
                                </td>
                                <td  class="text-one-sub f14" style="vertical-align: middle; text-align: right;" >
                                    {{pitcheo.Perdidos}}
                                </td>
                                <td class="text-one-sub f14" style="vertical-align: middle; text-align: right;">
                                    {{pitcheo.PCT}}
                                </td>
                                <td class="text-one-sub f14" style="vertical-align: middle; text-align: right;">{{pitcheo.IP}}</td>
                                <td  class="text-one-sub f14" style="vertical-align: middle; text-align: right;">{{pitcheo.JE}}</td>
                                <td class="text-one-sub f14" style="vertical-align: middle; text-align: right;">{{pitcheo.HA}}</td>
                                <td class="text-one-sub f14" style="vertical-align: middle; text-align: right;">{{pitcheo.TC}}</td>
                                <td class="text-one-sub f14" style="vertical-align: middle; text-align: right;">{{pitcheo.CL}}</td>
                                <td class="text-one-sub f14" style="vertical-align: middle; text-align: right;">{{pitcheo.PCL}}</td>
                                <td class="text-one-sub f14" style="vertical-align: middle; text-align: right;">{{pitcheo.BB}}</td>
                                <td class="text-one-sub f14" style="vertical-align: middle; text-align: right;">{{pitcheo.K}}</td>
                                <td class="text-one-sub f14" style="vertical-align: middle; text-align: right;">{{pitcheo.DB}}</td>
                            </tr>
                        </tbody>
                    </tr>

                </table>
            </div>
        </div>
        <!-- Aqui termina Pitcheo -->
            <!--
            <div class="row estadisticas border-bottom mt-4">
                <div class="col-12 text-one-sub f18">
                    <p class="borde-left">ANUNCIOS</p>
                </div>
            </div>
            <div class="row mt-4">
                
                <div class="col-12 px-0">
                    <ngb-carousel [interval]="5000" [showNavigationArrows]="false" style="height: 450px;">
                        <ng-template *ngFor="let premio of PremiosData" ngbSlide>
                            <img [src]="premio.foto_copa" alt="Random first slide" class="w-100 h-100" style="width: 100%;object-fit: cover;object-position: center center;">
                        </ng-template>
                    </ngb-carousel>
                </div>
            </div>
        -->
        </div>
    
</div>


<script>
    let x = document.querySelectorAll(".myFORMATO");
    for (let i = 0, len = x.length; i < len; i++) {
        let num = Number(x[i].innerHTML)
                  .toLocaleString('en');
        x[i].innerHTML = num;
        
    }
</script>