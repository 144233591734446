<div class="sidebar" data-color="green" data-background-color="black" data-image="./assets/imagenes/fondo.jpg">
    <!--
    Tip 1: You can change the color of the sidebar using: data-color="purple | azure | green | orange | danger"

    Tip 2: you can also add an image using data-image tag
-->
    <div class="logo simple-text logo-normal text-center">
        <img src="./assets/logos/logo.png" alt="" height="100"  width="70">
        <br>
        Liga Yucatán
        <br>
        {{temporada}}
        <br>
        <a *ngIf="perfil == 'representante'">{{equipo}}</a>
    </div>
    <div class="sidebar-wrapper">
    <ul class="nav">
        <li *ngFor="let menuItem of menuItems" class="nav-item {{menuItem.active}}" style="cursor: pointer;" (click)="activar(menuItem.id)" id="{{menuItem.id}}">
            <a class="nav-link" [routerLink]="[menuItem.path]">
                <i class="{{menuItem.icon}}"></i>
                <p>{{menuItem.title}}</p>
            </a>
        </li>
        <hr>
        <li class="nav-item" (click)="Logout()" style="cursor: pointer;">
            <a class="nav-link">
                <i class="fas fa-door-open"></i>
                <p>Cerrar sesión</p>
            </a>
        </li>
    </ul>
    </div>
</div>

