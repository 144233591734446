<div class="content" accept-charset="utf-8">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header card-header-primary">
                      <h4 class="card-title">JUGADORES</h4>
                      <!-- <p class="card-category">{{temporada}}</p> -->
                    </div>
                    <div class="card-body">
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-12 col-md-7">
                                    <mat-form-field class="w-100">
                                        <mat-label>Buscar jugador</mat-label>
                                        <input type="text"
                                               placeholder="Utilice el nombre o CURP del jugador"
                                               aria-label=""
                                               matInput
                                               (keyup)="busqueda()"
                                               [formControl]="myControl"
                                               [matAutocomplete]="auto"
                                               style="text-transform: uppercase;">
                                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                          <mat-option *ngFor="let jugador of jugadores_busqueda" [value]="jugador.IDJugador">
                                            {{judagor.nombre}}
                                          </mat-option>
                                        </mat-autocomplete>
                                      </mat-form-field>
                                </div>
                                <div class="col-md-1">
                                    <button class="btn btn-success" (click)="nuevoUsuario()"><i class="fas fa-user-plus"></i></button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-lg-8">
                                    <div class="table-responsive">
                                        <table *ngIf="jugadores.data.length > 0; else pintaVacio" mat-table [dataSource]="jugadores" class="w-100">
                                
                                            <ng-container matColumnDef="Id">
                                                <th mat-header-cell *matHeaderCellDef> ID </th>
                                                <td mat-cell *matCellDef="let element"> {{element.JugadorID}} </td>
                                            </ng-container>
                                            
                                            <ng-container matColumnDef="Nombre">
                                                <th mat-header-cell *matHeaderCellDef> NOMBRE </th>
                                                <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
                                            </ng-container>
                                            
                                            <ng-container matColumnDef="Fecha">
                                                <th mat-header-cell *matHeaderCellDef> FECHA NACIMIENTO</th>
                                                <td mat-cell *matCellDef="let element"> {{element.FechaNacimiento}} </td>
                                            </ng-container>
                
                                            <ng-container matColumnDef="TelPadre">
                                              <th mat-header-cell *matHeaderCellDef> TELEFONO PADRE </th>
                                              <td mat-cell *matCellDef="let element"> {{element.TelPadre}} </td>
                                            </ng-container>
                                            
                                            <ng-container matColumnDef="TelMadre">
                                                <th mat-header-cell *matHeaderCellDef> TELEFONO MADRE </th>
                                                <td mat-cell *matCellDef="let element"> {{element.TelMadre}} </td>
                                            </ng-container>
                    
                                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                                            <tr (click)="visualizar(row.JugadorID)" mat-row *matRowDef="let row; columns: displayedColumns;" style="cursor: pointer;"></tr>
                                        </table>
                                        
                                        <ng-template #pintaVacio>
                                            <p class="text-info text-center">Aun no se cuenta con jugadores</p>
                                        </ng-template>
                                        <mat-paginator 
                                            [pageSizeOptions]="[15, 50, 100]"
                                            showFirstLastButtons 
                                            aria-label="Select page of periodic elements">
                                        </mat-paginator>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-lg-4">
                                    <div class="card card-profile">
                                        <div class="card-avatar">
                                          <div class="avatar">
                                            <img class="img" [src]="foto"/>
                                          </div>
                                        </div>
                                        <div class="card-header">
                                            <h4 class="card-title" style="text-transform: uppercase;">{{jugador.apellido_p}} {{jugador.apellido_m}} {{jugador.nombre}}</h4>
                                            <h6 class="card-category">INFORMACIÓN DEL JUGADOR</h6>
                                        </div>
                                        <div class="card-body">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <h5>{{jugador.curp}}</h5>
                                                    </div>
                                                    <div class="col-lg-4 col-sm-12">
                                                        <h5>{{jugador.edad}}</h5>
                                                    </div>
                                                    <div class="col-lg-4 col-sm-12">
                                                        <h5>{{jugador.sexo}}</h5>
                                                    </div>
                                                    <div class="col-lg-4 col-sm-12">
                                                        <h5>{{jugador.telefono}}</h5>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-12 col-sm-12">
                                                        <h6 class="card-category">EQUIPO DE LA TEMPORADA</h6>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <h5>{{jugador.equipo}}</h5>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-12 col-sm-12">
                                                        <h6 class="card-category">Padres</h6>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <h5>{{jugador.apellido_pp}} {{jugador.apellido_mp}} {{jugador.nombre_p}}</h5>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <h5>{{jugador.apellido_pm}} {{jugador.apellido_mm}} {{jugador.nombre_m}}</h5>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <h6 class="card-category">Domicilio</h6>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <h5>C.{{jugador.calle}} #{{jugador.num_exterior}} {{jugador.cruzamiento_uno}} {{jugador.cruzamiento_dos}} COL.{{jugador.colonia}},{{jugador.municipio}}</h5>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <h6 class="card-category">Representante</h6>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <h5>{{jugador.apellido_p_rep}} {{jugador.apellido_m_rep}} {{jugador.nombre_rep}}</h5>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <h5>{{jugador.correo_rep}}</h5>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <h5>{{jugador.tel_rep}}</h5>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <h6 class="card-category">ACCIONES</h6>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 text-center">
                                                        <button class="btn btn-light mr-1" title="Generar reporte jugador"><i class="far fa-file-pdf"></i></button>
                                                        <button *ngIf="band_btn" class="btn btn-success mr-1" title="Agregar a equipo" (click)="nuevaAltaAEquipo()"><i class="fas fa-users"></i></button>
                                                        <button [matMenuTriggerFor]="menu" class="btn btn-info" title="Administrar jugador"><i class="fas fa-ellipsis-v"></i></button>
                                                        <mat-menu #menu="matMenu">
                                                            <button mat-menu-item (click)="editar()">
                                                                <mat-icon>edit</mat-icon>
                                                                <span>Editar</span>
                                                            </button>
                                                            <button mat-menu-item >
                                                                <mat-icon>delete</mat-icon>
                                                                <span>Eliminar</span>
                                                            </button>
                                                        </mat-menu>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
      <h2 *ngIf="tipoModal == 1">Nuevo jugador</h2>
      <h2 *ngIf="tipoModal == 2">Editar jugador</h2>
    </div>
    <div class="modal-body">
        <div class="container">
            <div class="form">
                <div class="row align-items-center">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="bmd-label-floating">CURP</label>
                      <input type="text" class="form-control" [disabled]="form"  [(ngModel)]="jugador.curp" name="curp" style="text-transform: uppercase;">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row align-items-center">
                      <div class="col-md-10">
                        <div>
                          <label for="formFile" class="form-label">Subir fotografía</label>
                          <input #myInput class="form-control" type="file" accept=".jpg" (change)="cambiarImagen($event)" name="file" [disabled]="form">
                        </div>
                      </div>
                      <div class="col-md-2">
                        <br>
                        <button class="btn btn-primary" [disabled]="form"><i class="fas fa-camera" (click)="tomarFoto()" style="cursor: pointer;" title="Tomar foto"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="bmd-label-floating">Apellido paterno</label>
                      <input type="text" class="form-control" [disabled]="form"  [(ngModel)]="jugador.apellido_p" name="app" style="text-transform: uppercase;">
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="bmd-label-floating">Apellido materno</label>
                      <input type="text" class="form-control" [disabled]="form" [(ngModel)]="jugador.apellido_m" name="apm" style="text-transform: uppercase;">
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="bmd-label-floating">Nombres (s)</label>
                      <input type="text" class="form-control" [disabled]="form" [(ngModel)]="jugador.nombre" name="nombre" style="text-transform: uppercase;">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="fecha" class="bmd-label-floating">Fecha de nacimiento</label>
                      <input type="date" class="form-control" [disabled]="form" [(ngModel)]="jugador.fecha_nacimiento" name="fecha_nacimiento" id="fecha">
                    </div>
                  </div>
                  <div class="col-md-4">
                      <div class="form-group">
                          <label class="bmd-label-floating">Sexo</label>
                          <select class="form-select" aria-label="Default select example"  [disabled]="form" [(ngModel)]="jugador.sexo" name="sexo">
                              <option selected disabled value="-1">Selecciona el sexo</option>
                              <option value="HOMBRE">MASCULINO</option>
                              <option value="MUJER">FEMENINO</option>
                          </select>
                      </div>
                  </div>
                  <div class="col-md-4">
                      <div class="form-group">
                        <label class="bmd-label-floating">Telefono</label>
                        <input type="text" class="form-control" [disabled]="form" [(ngModel)]="jugador.telefono" name="telefono" style="text-transform: uppercase;" maxlength="10">
                      </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="bmd-label-floating">Calle</label>
                      <input type="text" class="form-control" [disabled]="form" [(ngModel)]="jugador.calle" name="direccion" style="text-transform: uppercase;">
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="bmd-label-floating">Num. interior</label>
                      <input type="text" class="form-control" [disabled]="form" [(ngModel)]="jugador.num_int" name="direccion" style="text-transform: uppercase;">
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="bmd-label-floating">Num. exterior</label>
                      <input type="text" class="form-control" [disabled]="form" [(ngModel)]="jugador.num_exterior" name="direccion" style="text-transform: uppercase;">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="bmd-label-floating">Cruzamiento uno</label>
                      <input type="text" class="form-control" [disabled]="form" [(ngModel)]="jugador.cruzamiento_uno" name="direccion" style="text-transform: uppercase;">
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="bmd-label-floating">Cruzamiento dos</label>
                      <input type="text" class="form-control" [disabled]="form" [(ngModel)]="jugador.cruzamiento_dos" name="direccion" style="text-transform: uppercase;">
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="bmd-label-floating">Colonia</label>
                      <input type="text" class="form-control" [disabled]="form" [(ngModel)]="jugador.colonia" name="direccion" style="text-transform: uppercase;">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="bmd-label-floating">Municipio</label>
                      <input type="text" class="form-control" [disabled]="form" [(ngModel)]="jugador.municipio" name="mun" style="text-transform: uppercase;">
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="bmd-label-floating">Estado</label>
                      <input type="text" class="form-control" [disabled]="form" [(ngModel)]="jugador.estado" name="estado" style="text-transform: uppercase;">
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="bmd-label-floating">Codigo postal</label>
                      <input type="text" class="form-control" [disabled]="form" [(ngModel)]="jugador.cp" name="cp" style="text-transform: uppercase;" maxlength="5">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Notas</label>
                      <div class="form-group">
                        <label class="bmd-label-floating">Describe la nota del jugador</label>
                        <textarea class="form-control" rows="5" [disabled]="form" [(ngModel)]="jugador.nota" name="nota" style="text-transform: uppercase;"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                      <h4>INFORMACIÓN DEL TUTOR</h4>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                      <div class="form-group">
                        <label class="bmd-label-floating">Apellido paterno</label>
                        <input type="text" class="form-control" [disabled]="form"  [(ngModel)]="jugador.apellido_p_rep" name="apmp" style="text-transform: uppercase;">
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="bmd-label-floating">Apellido materno</label>
                        <input type="text" class="form-control" [disabled]="form" [(ngModel)]="jugador.apellido_m_rep" name="apmp" style="text-transform: uppercase;">
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="bmd-label-floating">Nombre (s)</label>
                        <input type="text" class="form-control" [disabled]="form" [(ngModel)]="jugador.nombre_rep" name="np" style="text-transform: uppercase;">
                      </div>
                    </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="bmd-label-floating">Telefono</label>
                      <input type="text" class="form-control" [disabled]="form" [(ngModel)]="jugador.tel_rep" name="tf" style="text-transform: uppercase;" maxlength="10">
                    </div>
                  </div>
                  <div class="col-md-4">
                      <div class="form-group">
                        <label class="bmd-label-floating">Correo</label>
                        <input type="email" class="form-control" [disabled]="form" [(ngModel)]="jugador.correo_rep" name="tff">
                      </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <h4>INFORMACIÓN DE LOS PADRES</h4>
                    </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                      <div class="form-group">
                        <label class="bmd-label-floating">Apellido paterno (Padre)</label>
                        <input type="text" class="form-control" [disabled]="form"  [(ngModel)]="jugador.apellido_pp" name="apmp" style="text-transform: uppercase;">
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="bmd-label-floating">Apellido materno (Padre)</label>
                        <input type="text" class="form-control" [disabled]="form" [(ngModel)]="jugador.apellido_mp" name="apmp" style="text-transform: uppercase;">
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="bmd-label-floating">Nombre (s) (Padre)</label>
                        <input type="text" class="form-control" [disabled]="form" [(ngModel)]="jugador.nombre_p" name="np" style="text-transform: uppercase;">
                      </div>
                    </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                      <div class="form-group">
                        <label class="bmd-label-floating">Apellido paterno (Madre)</label>
                        <input type="text" class="form-control" [disabled]="form" [(ngModel)]="jugador.apellido_pm" name="apm" style="text-transform: uppercase;">
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="bmd-label-floating">Apellido materno (Madre)</label>
                        <input type="text" class="form-control" [disabled]="form" [(ngModel)]="jugador.apellido_mm" name="amm" style="text-transform: uppercase;">
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="bmd-label-floating">Nombre (s) (Madre)</label>
                        <input type="text" class="form-control" [disabled]="form" [(ngModel)]="jugador.nombre_m" name="nombre_m" style="text-transform: uppercase;">
                      </div>
                    </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="bmd-label-floating">Telefono (Padre)</label>
                      <input type="text" class="form-control" [disabled]="form" [(ngModel)]="jugador.telefono_p" name="tf" style="text-transform: uppercase;" maxlength="10">
                    </div>
                  </div>
                  <div class="col-md-4">
                      <div class="form-group">
                        <label class="bmd-label-floating">Telefono (Madre)</label>
                        <input type="text" class="form-control" [disabled]="form" [(ngModel)]="jugador.telefono_m" name="tff" style="text-transform: uppercase;" maxlength="10">
                      </div>
                    </div>
                </div>
              </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-danger" (click)="modal.close('Save click')"><i class="far fa-times-circle"></i> Cerrar</button>
        <button class="btn btn-success" (click)="modificarEquipo()"><i class="far fa-check-circle"></i> Guardar</button>
    </div>
</ng-template>
<ng-template #equipos let-equipo>
  <div class="modal-header">
    <h2>Agregar jugador a equipo</h2>
  </div>
  <div class="modal-body">
      <div class="container">
        <form>
          <div class="form-row">
            <div class="form-group col-md-12">
              <input type="text" class="form-control" placeholder="Buscar equipo" [(ngModel)]="busqueda_equipo" (keyup)="buscarEquipo()" name="busqueda" style="text-transform: uppercase;">
              <div class="table-responsive mt-1" style="height: 250px;">
                <table class="table table-striped" style="overflow-y: scroll;">
                  <thead>
                    <tr>
                      <th colspan="1">Equipos</th>
                      <th colspan="1">Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let equipo of equipos_bus" style="cursor: pointer;">
                        <td rowspan="1">{{equipo.EquipoSeleccion}}</td>
                        <td rowspan="1">
                          <button title="Agregar jugador a este equipo" class="btn btn-sm btn-info" (click)="altaJugadorAEquipo(equipo.InscripcionID)"><i class="fas fa-location-arrow"></i></button>
                        </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </form>
      </div>
  </div>
  <div class="modal-footer">
      <button class="btn btn-danger" (click)="equipo.close('Save click')"><i class="far fa-times-circle"></i> Cancelar</button>
  </div>
</ng-template>
<ng-template #modal_camera let-camera>
    <div class="modal-header">
        <h2 class="modal-title" id="tituloModal">Tomar fotografia</h2>
    </div>
    <div class="modal-body">
        <div class="container">
            <div class="row">
                <div *ngIf="isCameraExist; else noCameraExist" class="col-12">
                    <div class="border border-success rounded text-center" style="width: 100%;height: 265px;">
                        <webcam [height]="450" [width]="350" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
                        *ngIf="showWebcam" [switchCamera]="nextWebcamObservable"></webcam>
                    </div>
                
                    <div *ngIf="errors.length > 0">
                        <h4>Error Messages:</h4>
                        <ul *ngFor="let error of errors">
                            <li>{{ error | json }}</li>
                        </ul>
                    </div>
                </div>
                
                <ng-template #noCameraExist>
                    <div class="border border-danger rounded text-center" style="display: flex;justify-content: center;align-items: center;width: 450px;height: 350px;">
                        Este dispositivo no cuenta con una camara disponible
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-warning" (click)="camera.close()">Cerrar</button>
        <button *ngIf="isCameraExist" type="button" class="btn btn-success" (click)="takeSnapshot()">Tomar foto</button>
    </div>
  </ng-template>